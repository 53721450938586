<template>
  <div>
    <f-card
      v-if="!message"
      :header="$t('labels.forgotPassword')"
      header-center
    >
      <f-form @submit="onSubmit">
        <f-col
          class="required"
          md="12"
        >
          <f-input
            v-model="email"
            rules="required"
            t-label="email"
            type="email"
          />
        </f-col>
        <div
          class="form-foooter"
          mt-5
        >
          <f-button
            v-model="email"
            :label="$t('labels.forgotPassword')"
            type="submit"
            variant="primary btn-block"
          />
        </div>
      </f-form>
    </f-card>
    <f-card
      v-else
      :header="$t('messages.forgotPasswordHeader')"
    >
      <p>
        {{ message }}
      </p>
    </f-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      message: '',
      isProduction: process.env.NODE_ENV === 'production'
    }
  },
  async mounted() {
    if (this.isProduction) {
      await this.$recaptchaLoaded()
    }
  },
  methods: {
    async onSubmit() {
      let recaptchaToken

      if (this.isProduction) {
        recaptchaToken = await this.$recaptcha('login')
      }

      await this.$http.post('/auth/forgotPassword', {
        email: this.email,
        recaptchaToken,
        url: window.location.origin
      })
      this.message = this.$t('messages.forgotPasswordMessage')
    }
  }
}
</script>
